
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { UtilService } from 'src/app/services/util.service';
import { CartService } from 'src/app/services/cart.service';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-headers',
  templateUrl: './headers.component.html',
  styleUrls: ['./headers.component.scss']
})
export class HeadersComponent implements OnInit {

  activeTab = 'home';
  headerMode: boolean;
  activeFilter: any;
  totalRest: any;
  LabLocd:any;
  languages: any;
  selectedLanguage: any;
  UsrLocation:any;

  constructor(
    private router: Router,
    public api: ApiService,
    public util: UtilService,
    private chmod: ChangeDetectorRef,
    public cart: CartService) {
    this.headerMode = false;
    this.selectedLanguage = 'English';
    this.getLangs();
    this.util.subscribeHeader().subscribe(data => {
      if (data) {
        this.headerMode = data.header;
        this.totalRest = data.total;
        if (data && data.active !== undefined) {
          this.activeFilter = data.active;
        }
      }

      this.chmod.detectChanges();
    });

    if(localStorage.getItem('lablocation')===null)
    {
      this.LocationConfirm(); 
    }
    else
    {
      this.LabLocd = localStorage.getItem('lablocation'); 
    }

  }

  async LocationConfirm() {
    console.log('LocationConfirm alert to ask!');
     
    Swal.fire({
      title: this.util.translate('Location'),
      text: this.util.translate(`Select Your City`),
      input: "select",
      inputOptions: {
        'l1': 'Delhi',
        'l2': 'Haridwar'
      },
      icon: 'info',
      //showCancelButton: true,
      showConfirmButton: true, 
      showLoaderOnConfirm: true,
      confirmButtonText: this.util.translate('Select'),
      //cancelButtonText: "Cancel",
       
      allowOutsideClick: () => !Swal.isLoading(),
       
      backdrop: false,
      background: 'white'
    }).then((result) => {
      if (result) 
        {
              this.UsrLocation = result.value;
              localStorage.setItem('lablocation',this.UsrLocation); 
              this.LabLocd = localStorage.getItem('lablocation'); 
              console.log('clear',localStorage.getItem('lablocation')); 
        }
    });

  }

  addFilter(item) {
    this.activeFilter = item;
    this.util.publishFilterCode(item);
  }

  changeLanguage(value) {
    const item = this.languages.filter(x => x.file === value.file);
    if (item && item.length > 0) {
      localStorage.setItem('language', value.file);
      window.location.reload();
    }
  }

  haveLocation() {
    const location = localStorage.getItem('location');
    if (location && location != null && location !== 'null') {
      return true;
    }
    return false;
  }

  haveSigned() {
    const uid = localStorage.getItem('uid');
    if (uid && uid != null && uid !== 'null') {
      return true;
    }
    return false;
  }

  openLink(item) {
    const name = (this.util.userInfo.first_name + '-' + this.util.userInfo.last_name).toLowerCase(); 
    this.router.navigate(['user', name, item]);
    this.chmod.detectChanges();
  }


  getLangs() {
    this.api.get('lang').then((data: any) => {
      console.log('data--->>> languages??===??', data);
      if (data && data.status === 200 && data.data.length) {
        this.languages = data.data;
        const lng = localStorage.getItem('language');
        if (lng && lng !== null && lng !== '') {
          const selectedLang = this.languages.filter(x => x.file === lng);
          console.log('selected language', selectedLang);
          if (selectedLang && selectedLang.length) {
            this.selectedLanguage = selectedLang[0].name;
          }
        } else {
          const defaultLanguages = this.languages.filter(x => x.is_default === '1');
          if (defaultLanguages && defaultLanguages.length) {
            this.selectedLanguage = defaultLanguages[0].name;
          }
        }
      } else {
        this.selectedLanguage = 'English';
      }
    }, error => {
      console.log(';error in languge', error);
      this.selectedLanguage = 'English';
    }).catch((error => {
      console.log('error->>>', error);
      this.selectedLanguage = 'English';
    }))
  }

  ngOnInit(): void {
  }

  goToHome() {
    this.activeTab = 'home';
    this.router.navigate(['/home']);
  }

  goToSearch() {
    this.activeTab = 'search';
    this.router.navigate(['/search']);
  }

  goToOffers() {
    this.activeTab = 'offers';
    this.router.navigate(['/offers']);
  }

  goToSettings(item) {
    this.activeTab = 'settings';
    const name = (this.util.userInfo.first_name + '-' + this.util.userInfo.last_name).toLowerCase();
    this.router.navigate(['user', name, item]);
  }

  goToAccount() {
    this.activeTab = 'account';
    this.router.navigate(['/account']);
  }

  goToCart() {
    this.activeTab = 'cart';
    this.router.navigate(['/show-cart']);
  }

  getAddress() {
    const location = localStorage.getItem('address');
    if (location && location != null && location !== 'null') {
      return location.length > 30 ? location.slice(0, 30) + '....' : location;;
    }
    return this.util.translate('Location');
  }

  
  logout() {
    localStorage.removeItem('uid');
    localStorage.removeItem('token');
    this.cart.cart = [];
    this.cart.itemId = [];
    this.cart.totalPrice = 0;
    this.cart.grandTotal = 0;
    this.cart.coupon = null;
    this.cart.discount = null;
    this.util.clearKeys('cart');
    this.router.navigate(['']);
  }

  goToHelp() {
    this.activeTab = 'help';
    this.router.navigate(['help']);
  }

  goToFaqs() {
    this.activeTab = 'faq';
    this.router.navigate(['faq']);
  }

  goToPartner() {
    this.activeTab = 'partner';
    this.router.navigate(['partner']);
  }

}


