export const environment = {
  production: false,
  baseURL: 'https://api.medibird.com/index.php/',
  mediaURL: 'https://api.medibird.com/uploads/',
  bannerURL: 'https://api.medibird.com/uploads/', 
  onesignal: {
    appId: '',
    googleProjectNumber: '',
    restKey: ''
  },
  authToken: 'RviWsecCBN_ofAtscnst32YhaubRtHNM',
  app_name: 'medibird.com',
  websiteURL: 'https://medibird.com/',
  websiteName: 'https://medibird.com/',
  default_country_code: '91'
};
