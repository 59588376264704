<!-- Health Package -->
<div class="health_package">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="center-heading mb-3">
                    <h5 class="sub-heading font28 w600">Packages</h5>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="healthSlider owl-carousel owl-theme">
                    <div class="item">
                        <div class="health_card">
                            <h4>HOME COLLECTION CHARGES</h4>
                            <p>Sub heading</p>
                            <div class="price">
                                <del>100</del>
                                ₹ 200
                            </div>
                            <!-- cart -->
                            <div class="addToCart">
                                <img src="assets/images/cart-add-icon.png">
                            </div>
                        </div>
                    </div>
                    <div class="item">
                        <div class="health_card">
                            <h4>HOME COLLECTION CHARGES</h4>
                            <p>Sub heading</p>
                            <div class="price">
                                <del>100</del>
                                ₹ 200
                            </div>
                            <!-- cart -->
                            <div class="addToCart">
                                <img src="assets/images/cart-add-icon.png">
                            </div>
                        </div>
                    </div>
                    <div class="item">
                        <div class="health_card">
                            <h4>HOME COLLECTION CHARGES</h4>
                            <p>Sub heading</p>
                            <div class="price">
                                <del>100</del>
                                ₹ 200
                            </div>
                            <!-- cart -->
                            <div class="addToCart">
                                <img src="assets/images/cart-add-icon.png">
                            </div>
                        </div>
                    </div>
                    <div class="item">
                        <div class="health_card">
                            <h4>HOME COLLECTION CHARGES</h4>
                            <p>Sub heading</p>
                            <div class="price">
                                <del>100</del>
                                ₹ 200
                            </div>
                            <!-- cart -->
                            <div class="addToCart">
                                <img src="assets/images/cart-add-icon.png">
                            </div>
                        </div>
                    </div>
                    <div class="item">
                        <div class="health_card">
                            <h4>HOME COLLECTION CHARGES</h4>
                            <p>Sub heading</p>
                            <div class="price">
                                <del>100</del>
                                ₹ 200
                            </div>
                            <!-- cart -->
                            <div class="addToCart">
                                <img src="assets/images/cart-add-icon.png">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>