<footer class="main-footer">
    <div class="container">
       <div class="logoSection">
            <!-- <a  class="logo active" href="#!"><img src="assets/images/logo-white.png" alt=""></a> --> 
            
            <nav>
                <a class="text-white" href="##">About</a>
                <a class="text-white">How It Work</a>
                <a href="#!">Terms &amp; Conditions</a>
                <a href="#!">Privacy Policy</a><a class="text-white">Contact Us</a>
                <!-- <a href="https://admin.medibird.com" target="_adm">Admin Login</a> -->
            </nav>
            <p class="Copyright text-white">Copyright @ medibird | 2023 </p>
            <div class="socialLinks">
               <a href="#" title="facebook"><i class="fa fa-facebook-f" aria-hidden="true"></i></a>
               <a href="#" title="twitter"><i class="fa fa-twitter" aria-hidden="true"></i></a>
               <a href="#" title="instagram"><i class="fa fa-instagram" aria-hidden="true"></i></a>
            </div>
                    <div class="clearfix"></div>
            
       </div>
    </div>
    <!-- <div class="bottomftr">
       <div class="container">
          <p class="Copyright">Copyright @ medibird | 2023 </p>
       </div>
    </div> -->
 </footer>