import { NgModule } from '@angular/core';
import { Routes, RouterModule, CanActivate, PreloadAllModules } from '@angular/router';
import { UsersComponent } from './layouts/users/users.component';
import { ErrorsComponent } from './layouts/errors/errors.component';
import { AuthGuard } from './guard/auth.guard';
import { LocationGuard } from './locationGuard/location.guard';
import { LeaveGuard } from './leaved/leaved.guard';

const routes: Routes = [
  {
    path: '',
    component: UsersComponent,
    children: [
      {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full'
      },
      {
        path: 'home',
        loadChildren: () => import('./components/home/home.module').then(m => m.HomeModule),
        data: { title: 'Home' }
      },
      {
        path: 'disorder/:disordername',
        loadChildren: () => import('./components/disorder-details/disorder-details.module').then(m => m.DisorderModule),
        data: { title: 'Home' }
      },
      {
        path: 'about',
        loadChildren: () => import('./components/about/about.module').then(m => m.AboutModule),
        data: { title: 'About' }
      },
      {
        path: 'thanks',
        loadChildren: () => import('./components/thanks/thanks.module').then(m => m.ThanksModule),
        data: { title: 'thanks' }
      },
      {
        path: 'contact',
        loadChildren: () => import('./components/contact/contact.module').then(m => m.ContactModule),
        data: { title: 'Contact' }
      }, 
      {
        path: 'faq',
        loadChildren: () => import('./components/faq/faq.module').then(m => m.FaqModule),
        data: { title: 'Faqs' }
      },
       
      {
        path: 'help',
        loadChildren: () => import('./components/help/help.module').then(m => m.HelpModule),
        data: { title: 'Help' }
      }, 
      {
        path: 'cart',
        loadChildren: () => import('./components/cart/cart.module').then(m => m.CartModule),
        canActivate: [AuthGuard],
        data: { title: 'Cart' }
      },
      {
        path: 'show-cart',
        loadChildren: () => import('./components/show-cart/cart.module').then(m => m.CartModule),
        //canActivate: [AuthGuard],
        data: { title: 'Show cart' }
      }, 
      {
        path: 'orders',
        loadChildren: () => import('./components/orders/orders.module').then(m => m.OrdersModule),
        data: { title: 'Orders' }
      },
      {
        path: 'bookings',
        loadChildren: () => import('./components/bookings/bookings.module').then(m => m.BookingsModule),
        data: { title: 'Bookings' }
      }, 
      {
        path: 'order-details',
        loadChildren: () => import('./components/order-details/order-details.module').then(m => m.OrderDetailsModule),
        data: { title: 'Orders Details' },
        canDeactivate: [LeaveGuard]
      },
      {
        path: 'rate',
        loadChildren: () => import('./components/rate/rate.module').then(m => m.RateModule),
        data: { title: 'Rate' }
      },
      {
        path: 'labtest',
        loadChildren: () => import('./components/labtest/labtest.module').then(m => m.LabtestModule),
        data: { title: 'Rate' }
      },
      {
        path: 'labtest/:test/:testid',
        loadChildren: () => import('./components/test-detail/test-detail.module').then(m => m.TestDetailModule),
        data: { title: 'Rate' }
      },
      {
        path: 'labtest-filter/:filter/:filterid',
        loadChildren: () => import('./components/labtest/labtest.module').then(m => m.LabtestModule),
        data: { title: 'Rate' }
      },
      {
        path: 'testdetails',
        loadChildren: () => import('./components/test-detail/test-detail.module').then(m => m.TestDetailModule),
        data: { title: 'Test Details' }
      }, 
      {
        path: 'user/:id/:from',
        loadChildren: () => import('./components/settings/settings.module').then(m => m.SettingsModule),
        data: { title: 'User Informations' }
      },
      {
        path: 'notice',
        loadChildren: () => import('./components/notice/notice.module').then(m => m.NoticeModule),
        data: { title: 'Notice' }
      },
      {
        path: 'cookie',
        loadChildren: () => import('./components/cookie/cookie.module').then(m => m.CookieModule),
      },
       
      {
        path: 'paytmcallback',
        loadChildren: () => import('./components/paytmcallback/paytmcallback.module').then(m => m.PaytmcallbackModule),
        data: { title: 'Success' }
      },
      {
        path: 'instamojocallback',
        loadChildren: () => import('./components/instamojocallback/instamojocallback.module').then(m => m.InstamojocallbackModule),
        data: { title: 'Success' }
      },
      {
        path: 'flutterwavecallback',
        loadChildren: () => import('./components/flutterwavecallback/flutterwavecallback.module').then(m => m.FlutterwavecallbackModule),
        data: { title: 'Success' }
      }, 
      {
        path: 'terms',
        loadChildren: () => import('./components/terms/terms.module').then(m => m.TermsModule),
        data: { title: 'Terms-Conditions' }
      },
      {
        path: 'refund',
        loadChildren: () => import('./components/refund/refund.module').then(m => m.RefundModule),
        data: { title: 'Refund & Cancellation' }
      },
      {
        path: 'privacy',
        loadChildren: () => import('./components/privacy/privacy.module').then(m => m.PrivacyModule),
        data: { title: 'Privacy Policy' }
      }

    ]
  },
  {
    path: '**',
    component: ErrorsComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
