<div class="thumbBannerSection">
    <div class="container">
        <div class="row">
            <div class="col-md-3">
                <div class="banner">
                    <div class="img-container">
                        <img src="assets/images/dummy-img.jpg" alt="">
                    </div>
                    <div class="content">
                        <h5>Heart Disease Package</h5>
                        <a href="#!" class="btn btn-primary-sm">View All</a>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="banner">
                    <div class="img-container">
                        <img src="assets/images/dummy-img.jpg" alt="">
                    </div>
                    <div class="content">
                        <h5>Heart Disease Package</h5>
                        <a href="#!" class="btn btn-primary-sm">View All</a>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="banner">
                    <div class="img-container">
                        <img src="assets/images/dummy-img.jpg" alt="">
                    </div>
                    <div class="content">
                        <h5>Heart Disease Package</h5>
                        <a href="#!" class="btn btn-primary-sm">View All</a>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="banner">
                    <div class="img-container">
                        <img src="assets/images/dummy-img.jpg" alt="">
                    </div>
                    <div class="content">
                        <h5>Heart Disease Package</h5>
                        <a href="#!" class="btn btn-primary-sm">View All</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>