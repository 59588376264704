
<header class="main_header">
  <div class="container">
    <div class="header_items">
      <div class="brand_items">
        <a class="navbar-brand" (click)="goToHome()">
          <img src="assets/images/logo-dark.png" class="">
        </a>
        <!-- <label class="state_lbl" (click)="util.publishModalPopup('location')">
          {{getAddress()}} <i class="fa fa-angle-down">
          </i>
        </label> -->
      </div>
      <div class="cart-contener">
        <a class="nav-link yourCart" href="/show-cart" >
          <img src="assets/images/cart.png" alt="show-cart"/>
          <span class="count">{{cart.totalItem}}</span>
        </a>
      
        <div class="menu_btn" mdbWavesEffect (click)="util.publishModalPopup('sideMenu')">
          <i class="fa fa-align-right"></i>
        </div>
      </div>
    </div>
  </div>
</header>
