import { Component, OnInit } from '@angular/core';

declare var $: any;
@Component({
  selector: 'app-disorder',
  templateUrl: './disorder.component.html',
  styleUrls: ['./disorder.component.scss']
})
export class DisorderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    // Life cycle slider
    $('.lifecycle_Slider').owlCarousel({
      loop:false,
      nav:true,
      autoplay:true,
      autoplayTimeout:4000,
      dots: false,
      lazyLoad: true,
      smartSpeed:3000,
      responsive:{
          0:{
              items:2
          },
          600:{
              items:5
          },
          1000:{
              items:7
          }
      }
    });
  }

}
