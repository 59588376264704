<header class="main-header">
    <nav class="container">
        <div class="navbar navbar-expand-lg">		  
              <a class="navbar-brand" href="/home"><img src="assets/images/logo-dark.png" alt=""></a>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
              <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                <li class="nav-item">
                  <a class="nav-link" aria-current="page" href="/home">Home</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" aria-current="page" href="/about">About</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link active" href="/labtest">Health Package</a>
                </li> 
                <li class="nav-item">
                  <a class="nav-link" href="/user/name/dashboard">Online Report</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="/labtest">Book a Test Now</a>
                </li>  
                <li class="nav-item">
                  <a class="nav-link" href="/contact">Contact Us</a>
                </li>
                <li class="nav-item" *ngIf="haveSigned()">
                  <a class="nav-link" href= 'javascript:void(0)' (click)="openLink('dashboard')" >My Dashboard</a>
                </li>               
              </ul>
              <ul class="navbar-nav navbar-right">
                <li class="nav-item">
                    <a class="nav-link btn btn-primary-sm lineH23" (click)="util.publishModalPopup('login')" *ngIf="haveSigned() ===false">Login</a>
                    <a class="nav-link btn btn-primary-sm lineH23" (click)="logout()" *ngIf="haveSigned()">Logout</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link yourCart" href="/show-cart" >
                      <img src="assets/images/cart.png" alt="cart"/>
                      <span class="count">{{cart.totalItem}}</span>
                  </a>
              </li>
              </ul>
            </div>
        </div>			
    </nav>
</header>