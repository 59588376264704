import { Component, OnInit } from '@angular/core';

declare var $: any;
@Component({
  selector: 'app-package',
  templateUrl: './package.component.html',
  styleUrls: ['./package.component.scss']
})
export class PackageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    // Health package slider
    $('.healthSlider').owlCarousel({
      loop:false,
      nav:true,
      margin: 15,
      autoplay:true,
      autoplayTimeout:4000,
      dots: false,
      lazyLoad: true,
      smartSpeed:3000,
      responsive:{
          0:{
              items:1
          },
          600:{
              items:2
          },
          1000:{
              items:4
          }
      }
    });
  }

}
