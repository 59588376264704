<!-- Lifecycle Disorder -->
<div class="lifecycle">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="header">
                    <h2>Lifecycle Disorder</h2>
                    <a href="#!" class="btn btn-text">View All</a>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="lifecycle_Slider owl-carousel owl-theme">
                    <div class="item">
                        <a href="#">
                            <div class="diseas_img">
                                <img src="assets/images/test/lungs.png">
                            </div>
                            <p>Lungs</p>
                         </a>
                    </div>
                    <div class="item">
                        <a href="#">
                            <div class="diseas_img">
                                <img src="assets/images/test/Infertility.png">
                            </div>
                            <p>Infertility</p>
                         </a>
                    </div>
                    <div class="item">
                        <a href="#">
                            <div class="diseas_img">
                                <img src="assets/images/test/cbc.png">
                            </div>
                            <p>CBC</p>
                         </a>
                    </div>
                    <div class="item">
                        <a href="#">
                            <div class="diseas_img">
                                <img src="assets/images/test/thyroid.png">
                            </div>
                            <p>Thyroid</p>
                         </a>
                    </div>
                    <div class="item">
                        <a href="#">
                            <div class="diseas_img">
                                <img src="assets/images/test/vitamin.png">
                            </div>
                            <p>Vitamin-D</p>
                         </a>
                    </div>
                    <div class="item">
                        <a href="#">
                            <div class="diseas_img">
                                <img src="assets/images/test/heart.png">
                            </div>
                            <p>Heart</p>
                         </a>
                    </div>
                    <div class="item">
                        <a href="#">
                            <div class="diseas_img">
                                <img src="assets/images/test/liver.png">
                            </div>
                            <p>Liver</p>
                         </a>
                    </div>
                    <div class="item">
                        <a href="#">
                            <div class="diseas_img">
                                <img src="assets/images/test/allergy.png">
                            </div>
                            <p>Allergy</p>
                         </a>
                    </div>
                    <div class="item">
                        <a href="#">
                            <div class="diseas_img">
                                <img src="assets/images/test/kidney.png">
                            </div>
                            <p>Kidney</p>
                         </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>