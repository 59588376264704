
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
  if (window) {
    console.log('MediBird.com');
    /*
    01000011 01101111 01110000 01111001 01110010 01101001 01100111 01101000 01110100 00100000 01100001 01101110 01100100 00100000 01000111 01101111 01101111 01100100 00100000 01000110 01100001 01101001 01110100 01101000 00100000 01010000 01110101 01110010 01100011 01101000 01100001 01110011 01100101 01110010 01110011 00100000 11000010 10101001 00100000 00110010 00110000 00110010 00110000 00101101 01110000 01110010 01100101 01110011 01100101 01101110 01110100 00100000 01101001 01101110 01101001 01110100 01100001 01110000 01110000 01111010 00100000 00101000 01010010 01100001 01101000 01110101 01101100 00100000 01001010 01101111 01100111 01110010 01100001 01101110 01100001 00101001
    */
    window.console.log = function () { };
  }
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
