<div class="container">
    <div class="top-search-bar">
        <div class="widget"><input type="search" placeholder="location, Zip Code" class="form-control"></div>
        <div class="top-search-field">
            <div class="ng-autocomplete">
                <select class="form-control">
                    <option>Select Location</option>
                    <option>1</option>
                    <option>2</option>
                </select>
            </div>
        </div>
     </div>
</div>